.dark-theme-map .leaflet-control-zoom a {
  background-color: #333;
  color: #fff;
  border-color: #666;
}

.dark-theme-map .leaflet-control-zoom a:hover {
  background-color: #444;
}

.dark-theme-map .leaflet-control-attribution {
  background-color: rgba(0, 0, 0, 0.5);
  color: #aaa;
}

.dark-theme-map .leaflet-control-attribution a {
  color: #ddd;
}

/* Custom dark theme for popups */
.leaflet-popup-content-wrapper {
  background-color: #2d3748; /* Medium grey background */
  color: #e2e8f0; /* Light grey text */
  border-radius: 4px;
  padding: 0; /* Remove default padding */
}

.leaflet-popup-content {
  margin: 8px 12px; /* Adjust this to control the padding inside the popup */
  line-height: 1.4;
}

.leaflet-popup-tip {
  background-color: #2d3748; /* Match the popup background */
}

.leaflet-popup-close-button {
  color: #e2e8f0; /* Light grey for the close button */
}

.dark-popup-content {
  cursor: pointer;
  padding: 4px 0; /* Add some vertical padding within the content */
}

.dark-popup-content:hover {
  color: #90cdf4; /* Light blue on hover */
}

/* Adjust the size of the popup */
.leaflet-popup {
  min-width: 50px; /* Adjust this value as needed */
}

.leaflet-popup-content {
  min-width: 30px; /* Adjust this value as needed */
}
