.leaflet-tile-container img {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
}

/* Add a background color to the map container */
.leaflet-container {
  background-color: #242f3e;
}
